@import "@livingmap/core-ui-v2";

.container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  width: 420px;
  min-width: 420px;
  border-left: 1px solid #e0e0e0;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 420px;
  min-width: 420px;
}

.sidebarTop {
  border-bottom: 1px solid #e0e0e0;
  padding: 20px 24px;
}

.sidebarHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebarTitle {
  @include font-title-large;
  color: $grey-tone-900
}

.pillWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 4px -4px;
}

.pillWrapper > div {
  margin: 4px;
}

.sidebarMiddle {
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  
  .sidebarSection {
    display: flex;
    flex-direction: column;
    position: relative; /* Add this to position the pseudo-element */

    &:not(:last-child)::after { /* Apply the divider to all but the last section */
      content: ""; /* Required for the pseudo-element to be generated */
      position: absolute;
      bottom: 0; /* Align it to the bottom of the section */
      left: 24px; /* Distance from the left edge */
      right: 24px; /* Distance from the right edge, creating the 'padding' effect */
      border-bottom: 1px solid $grey-tone-200; /* Your desired border style */
    }

    .header {
      padding: 24px;
      @include font-title-medium;
    }

    .body {
      display: flex;
      flex-direction: column;
      padding: 0 24px;
      gap: 20px;
      padding-bottom: 24px;

      .codeAndInput {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;

        .image {
          width: 200px;
          height: 200px;
        }

        .input {
          width: 300px;
          display: flex;
          align-items: center;
          gap: 8px;
          color: $grey-tone-600;

          > svg {
            cursor: pointer;
          }
        }
      }
      
      .qrCodeControls {
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 12px;
      }

      .details {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .deleteButton {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.sidebarBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e0e0e0;
  padding: 20px 24px;
}

.saveCancelButtonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-left: auto;
}

.linkOptionsContainer button {
  background-color: transparent;
}
